:root {
  --color-black: #212121;
  --color-white: #ffffff;
  --color-monochrome: #424242;
  --color-lighter-monochrome: #757575;
  --color-red-light: #e73d3a;
  --color-red: #d9221c;
  --color-blue: #0074bd;
  --color-blue-light: #e8f5fc;
  --color-darker-blue: #0064A4;
  --color-green: #2b8000;
  --color-orange: #ad5f00;
  --color-light-orange: #ffd4c1;
  --color-grey: #e0e0e0;

  --color-background: #f7f7f7;
  --color-error-background: rgba(217, 34, 28, 0.05);
  --color-box-shadow: rgba(0, 0, 0, 0.04);
  --color-border: #9e9e9e;
  --color-border-light: rgba(0, 0, 0, 0.12);
  --color-selected: #eff6fb;
  --color-backdrop: rgba(0, 0, 0, 0.5);
}
