.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 99999;
}
#menu-.MuiModal-root {
  z-index: 99999;
}
#column-filter-popover {
  z-index: 99999;
}
#item-action-menu {
  z-index: 99999;
}
