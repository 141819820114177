.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

svg.inline-icon {
  width: 20px;
  margin-right: 3px;
  display: inline-block;
}

.centered {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.spaced {
  justify-content: space-between;
  width: 100%
}

.link {
  color: var(--color-blue);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.no-select {
  user-select: none;
}

.mt24 {
  margin-top: 24px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.drag-ghost, .drag-placeholder {
  display: flex;
  pointer-events: none;
  position: fixed;
  cursor: grabbing;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 34px;
  width: 100%;
  padding: 0 2px;
  border: 1px solid var(--color-blue);
  background-color: var(--color-selected);
}

.drag-placeholder {
  position: relative;
  z-index: 1;
  opacity: 0.3;
}

.placeholder-content {
  overflow: hidden;
  position: relative;

  // Animation
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8; // Fallback
  background: linear-gradient(to right, var(--color-background) 2%, var(--color-selected) 18%, var(--color-background) 33%);
  background-size: 1300px; // Animation Area

  width: 100%;
  height: 20px;
  z-index: 2;
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
