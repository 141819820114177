@font-face {
  font-family: "latoBold";
  src: local("latoBold"), url(../fonts/Lato-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "sourceSansProBold";
  src: local("sourceSansProBold"),
  url(../fonts/SourceSansPro-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "sourceSansProSemiBold";
  src: local("sourceSansProSemiBold"),
  url(../fonts/SourceSansPro-SemiBold.woff2) format("woff2");
}

@font-face {
  font-family: "sourceSansProRegular";
  src: local("sourceSansProRegular"),
  url(../fonts/SourceSansPro-Regular.woff2) format("woff2");
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

:root {
  --font-regular: 'sourceSansProRegular', sans-serif;
  --font-semi-bold: 'sourceSansProSemiBold', sans-serif;
  --font-bold: 'sourceSansProBold', sans-serif;
  --font-bolder: 'latoBold', sans-serif;
  --font-sans: 'Roboto', sans-serif;
}
